/* You can add global styles to this file, and also import other style files */
@import "../node_modules/reset-css/sass/_reset.scss";
@import "./styles/_colors.scss";

@font-face {
  font-family: "Pixelar Regular W01 Regular";
  src: url("/assets/fonts/pixelar.woff2") format("woff2"),
    url("/assets/fonts/pixelar.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

html,
body {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background-color: $black;
  color: $white;

  * {
    color: $white;
    font-family: "Pixelar Regular W01 Regular";
    outline: none !important;
  }

  a {
    text-decoration: none;
  }
}

body {
  background-image: url("/assets/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
